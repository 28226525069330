@import "variables.scss";
.repo-tool {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  &-tracker{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: $gray3;
    .object-holder{
      flex: 1;
      display: flex;
    }

    .buttons{
      display: flex;
      align-items: center;
      border-bottom: 0.0625rem solid $gray4; 
    }

    .target-object{
      cursor: pointer;
      .object-name{
        cursor: pointer;
      }
    }
  }

  &-content{
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: $white;

    .SplitPane {
      overflow: hidden;
      position: relative !important;
    }
    .Pane {
      overflow: hidden;
      position: relative !important;
      display: flex;
    }

    .Pane1{
      overflow: auto!important;
    }

    .Resizer {
      background: #000;
      opacity: 0.2;
      z-index: 1;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -moz-background-clip: padding;
      -webkit-background-clip: padding;
      background-clip: padding-box;
    }

    .Resizer:hover {
      -webkit-transition: all 2s ease;
      transition: all 2s ease;
    }

    .Resizer.horizontal {
      height: 11px;
      margin: -5px 0;
      border-top: 5px solid rgba(255, 255, 255, 0);
      border-bottom: 5px solid rgba(255, 255, 255, 0);
      cursor: row-resize;
      width: 100%;
    }

    .Resizer.horizontal:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
      width: 11px;
      margin: 0 -5px;
      border-left: 5px solid rgba(255, 255, 255, 0);
      border-right: 5px solid rgba(255, 255, 255, 0);
      cursor: col-resize;
    }

    .Resizer.vertical:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
    .Resizer.disabled {
      cursor: not-allowed;
    }
    .Resizer.disabled:hover {
      border-color: transparent;
    }
  }
  
  .preloader-wrapper {
    position: absolute;
    left:0;
    top:0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: $mainblue;
    }

  }
}
