@import "~@ais3p/ui-framework/colors.scss";

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;

  .uif-components-button {
    font-family: "Roboto";
  }
  #ais-root {
    height: 100%;
    width: 100%;
    display: flex;


    .validate {
      &.rejected {
        fill: #b4000e;
      }

      &.accepted {
        fill: #00b429;
      }
    }
    .expander.big,
    .icon {
      &.rejected {
        fill: #b4000e;
      }

      &.accepted {
        fill: #00b429;
      }
    }

    .app-header {
      .uif-components-button {
        background: transparent;
        flex: 1;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: auto;
        max-width: none;
        min-width: 2.75rem;
        min-height: 2.75rem;

        &:hover {
          color: $mainblue;
          background-color: rgba(0, 0, 0, 0.3);
        }
        > svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

