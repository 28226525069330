pre.code-viewer {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 2rem !important;
  border-radius: 0 !important;
  overflow: auto !important;
  box-sizing: border-box;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string{
  background: none!important;
}
.code-content {
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  // width: 100%;
  // height: 100%;
  position: relative;

  &-empty{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem;
    font-weight: 400;
    color: $mainblue;
  }

  .prism-container {
    flex: 1;
    overflow: auto;
    display: flex;
    padding: 0rem;

    > pre {
      flex: 1;
      height: auto;
      margin: 0rem !important;
    }
  }

  .prism-code{
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
  .code-line, .token-line {
    display: table-row; 
    min-width: 100%;
    
    .code-icon{
      cursor: pointer;
    }
    
    .indicator-container{
      display: table-cell;
      .indicator{
        display: block;
        &::before{
          content: " ";
        }
        
      }
    }

    &-num{
      display: table-cell;
      text-align: right;
      padding-right: .5rem;
      user-select: none;
      opacity: 0.5; 
      &.focused {
        color: $mainblue;
      }
    }

    &-controls{
      display: table-cell;
      // min-width: 3rem;
      // padding-right: .25rem;
      svg {
        font-size:  2rem;
        &.focused {
          color: $mainblue;
        }
      }
      text-align: left;
      user-select: none;
      &.focused{
        background-color: $blue1;
      }
    }

    &-content {
      display: table-cell;
      min-width: 100%;
      &.focused{
        background-color: $blue1;
      }
    }
  }

  .code-viewer {
    flex: 1;
    overflow: auto;
    height: 100%;
  }
  

  @import "./revision.scss";
}